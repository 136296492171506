import InfiniteScroll from 'infinite-scroll';

const zoom_images = (zoom) => {
  let size;
  if (zoom >= 8) {
    size = 100;
  } else if (zoom >= 7) {
    size = 250;
  } else if (zoom >= 6) {
    size = 500;
  } else if (zoom >= 4) {
    size = 1000;
  } else {
    size = 1500;
  }
  console.log(size);

  document.querySelectorAll('.day img').forEach((img) => {
    if (!img._sizes) img._sizes = JSON.parse(img.dataset.sizes);
    if (img.src != img._sizes[size]) img.src = img._sizes[size];
  });
};

window.addEventListener('DOMContentLoaded', () => {
  let scroll;

  document.querySelectorAll('[data-toggle-expanded-nav]').forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();

      document.querySelector('body').classList.toggle('expanded');
    });
  });

  document.querySelectorAll('#traits [data-toggle-expanded-trait]').forEach((el) => {
    el.addEventListener('click', (e) => {
      document.querySelector(`.trait[data-trait="${el.dataset.toggleExpandedTrait}"]`).classList.toggle('expanded');
    });
  });

  document.querySelectorAll('#zoom .button[data-zoom]').forEach((el) => {
    const grid = document.querySelector('#grid');

    zoom_images(grid.dataset.rows);

    el.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (el.dataset.zoom == 'in') {
        grid.dataset.rows = Math.max(1, parseInt(grid.dataset.rows) - 1);
      } else {
        grid.dataset.rows = Math.min(10, parseInt(grid.dataset.rows) + 1);
        if (scroll) scroll.loadNextPage();
      }

      zoom_images(grid.dataset.rows);
    });
  });

  const grid = document.querySelector('[data-infinite-grid]');
  const more = document.querySelector('[data-infinite-grid] .page.next a');

  if (grid && more) {
    scroll = new InfiniteScroll(grid, {
      path: '.page.next a',
      append: '.day',
      history: false,
      prefill: true,
    });
    scroll.on('append', () => {
      const grid = document.querySelector('#grid');
      if (grid) zoom_images(grid.dataset.rows);
    });
  }
});
