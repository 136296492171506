import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.RAILS_ENV || 'development',
  tracesSampleRate: 0,
  ignoreErrors: ['webSocket.onclose', 'TypeError: Failed to fetch', 'TypeError: NetworkError when attempting to fetch resource.', 'Failed to fetch', 'TypeError: Load failed', 'Load failed'],
});
