import React from 'react';
import { ethers } from 'ethers';
import { mainnet } from 'wagmi/chains';
import { WagmiConfig, createClient, useAccount, usePrepareContractWrite, useContractWrite, useContractRead } from 'wagmi';
import { ConnectKitProvider, getDefaultClient } from 'connectkit/build/index.es.js';

import buttonOpensea from '../../assets/images/button-opensea.svg';
import buttonEtherscan from '../../assets/images/button-etherscan.svg';

const ADDRESS = '0x2c25ce13dbf95dc76b2fc5e85e39c5f7efaf3e9e';
// NOTE: sync price with contract
const PRICE = '0.05';
const HIGHEST_ID = 8402;

// NOTE: change to production
const OPENSEA_URL_BASE = `https://opensea.io/assets/ethereum/${ADDRESS}/`;
const ETHERSCAN_URL_BASE = `https://etherscan.io/token/${ADDRESS}?a=`;
const ZORA_API_URL_BASE = `https://d2ydi2tmw4j98m.cloudfront.net/token/${ADDRESS}/`;

const errorReason = (error) => {
  if (!(error && error.reason)) return 'Unknown Error';
  return error.reason.replace('execution reverted: ERC721: ', '');
};

const isAlreadyMinted = (error) => {
  if (!(error && error.reason)) return false;
  return error.reason.indexOf('token already minted') !== -1;
};

const notEnoughFunds = (error) => {
  if (!(error && error.reason)) return false;
  return error.reason.indexOf('insufficient funds') !== -1;
};

const existingButtons = (tokenId) => {
  return (
    <div className="mint-button">
      <div className="links" style={!Boolean(tokenId) ? { opacity: '0.5' } : null}>
        <a href={!Boolean(tokenId) ? null : [OPENSEA_URL_BASE, tokenId].join('')} target="_blank">
          <img src={buttonOpensea} alt="opensea" />
        </a>

        <a href={!Boolean(tokenId) ? null : [ETHERSCAN_URL_BASE, tokenId].join('')} target="_blank">
          <img src={buttonEtherscan} alt="etherscan" />
        </a>
      </div>
    </div>
  );
};

/*
  TODO:
    - show txn pending state
*/

function Button(props) {
  const { config, error, isError } = usePrepareContractWrite({
    address: ADDRESS,
    abi: [
      {
        name: 'mint',
        type: 'function',
        stateMutability: 'payable',
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        outputs: [],
        payable: true,
      },
    ],
    overrides: {
      value: ethers.utils.parseEther(PRICE),
    },
    functionName: 'mint',
    args: [parseInt(props.tokenId)],
    enabled: Boolean(props.tokenId),
  });

  const writeMint = useContractWrite(config);

  if (props.isDisconnected) {
    const [exists, setExists] = React.useState(null);
    React.useEffect(() => {
      const check_exists = async () => {
        const result = await fetch([ZORA_API_URL_BASE, props.tokenId].join(''));
        console.log(result);
        setExists(result.status == 200);
      };

      check_exists();
    }, []);

    if (exists === null) {
      return existingButtons();
    } else if (exists === true) {
      return existingButtons(props.tokenId);
    } else if (props.tokenId > HIGHEST_ID) {
      return (
        <div className="mint-button">
          <div className="error">Day is not collectable yet.</div>
        </div>
      );
    } else if (exists === false) {
      return (
        <div className="mint-button">
          <div className="error">Connect your wallet to mint this day.</div>
          <button disabled={true}>Mint day {props.tokenId}</button>
        </div>
      );
    } else {
      return existingButtons(props.tokenId);
    }
  }

  if (props.tokenId > HIGHEST_ID) {
    return (
      <div className="mint-button">
        <div className="error">Day is not collectable yet.</div>
      </div>
    );
  } else if (isError && isAlreadyMinted(error)) {
    return existingButtons(props.tokenId);
  } else if (isError && notEnoughFunds(error)) {
    return (
      <div className="mint-button">
        <div className="error">{PRICE} ETH is required.</div>
        <button disabled={true}>Mint day {props.tokenId}</button>
      </div>
    );
  } else {
    return (
      <div className="mint-button">
        {isError && <div className="error">Error: {errorReason(error)}</div>}
        <button disabled={!writeMint.write} onClick={() => writeMint.write?.()}>
          Mint day {props.tokenId} for {PRICE} ETH
        </button>
        <div className="agree">
          By minting you agree to the <a href="/terms">Terms of Service</a>.
        </div>
      </div>
    );
  }
}

// NOTE: sync with ConnectButon
const client = createClient(
  getDefaultClient({
    appName: 'everyday.photo',
    infuraId: '64a57db108dc489ba5b1c2c81a857eaa',
    chains: [mainnet],
  }),
);

const MintButton = (props) => {
  const { isConnected, isDisconnected } = useAccount();

  return (
    <WagmiConfig client={client}>
      <ConnectKitProvider>
        {isConnected && <Button tokenId={props.tokenId} />}
        {isDisconnected && <Button isDisconnected={true} tokenId={props.tokenId} />}
      </ConnectKitProvider>
    </WagmiConfig>
  );
};

export default MintButton;
