import React from 'react';
import { mainnet } from 'wagmi/chains';
import { WagmiConfig, createClient } from 'wagmi';
import { ConnectKitProvider, ConnectKitButton, getDefaultClient } from 'connectkit/build/index.es.js';

// NOTE: sync with MintButton
const client = createClient(
  getDefaultClient({
    appName: 'everyday.photo',
    infuraId: '64a57db108dc489ba5b1c2c81a857eaa',
    chains: [mainnet],
  }),
);

const truncate = (address) => {
  // via https://github.com/gpxl-dev/truncate-eth-address
  // Captures 0x + 4 characters, then the last 4 characters
  const match = address.match(/^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

const ConnectButton = (props) => {
  return (
    <WagmiConfig client={client}>
      <ConnectKitProvider>
        <ConnectKitButton.Custom>
          {({ isConnected, show, address, ensName }) => {
            return <button onClick={show}>{isConnected ? ensName || truncate(address) : 'Connect'}</button>;
          }}
        </ConnectKitButton.Custom>
      </ConnectKitProvider>
    </WagmiConfig>
  );
};

export default ConnectButton;
